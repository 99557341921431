import React from 'react';
//import logo from './logo.svg';
import './App.css';
import Header from './Header';
import {BrowserRouter as Router, Route} from 'react-router-dom'
import About from './sections/About';
import { Contact } from './sections/Contact';
import Footer from './sections/Footer';
import Portfolio from './sections/Portfolio';

function App() {
  return (
    
    <Router>
    <Header/>
     <Route path="/about" component={About} />
     <Route path="/contact" component={Contact} />
     <Route path="/portfolio" component={Portfolio} />
     <Footer/>
    </Router>
  );
}

export default App;
