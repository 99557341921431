import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            <footer class="bg-black">
            <div class="footer-bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-sm-12 text-center">
                            <span class="font-size13 text-uppercase letter-spacing-1 text-light-gray">&copy; 2019 Crysto. Powered by Netron IT</span>
                        </div>
                    </div>
                </div>
            </div>
            <div id="back-to-top" class="back-to-top reveal">
                <i class="fa fa-angle-up"></i>
            </div>
        </footer>
        )
    }
}

export default Footer
