import React, { Component } from 'react'

export class Home extends Component {
    render() {
        return (
            <div className="bg-light-gray padding-100px-top">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center" style={{}}>
                        <img src="/img/me.jpg" style={{height:'200px'}} className="rounded-circle mb-2" alt="Crysto" />
                    </div>
                    <div className=" offset-md-3 col-md-6 order-2 mb-5">

                        <div className="xs-no-padding">
                            <h5 className="no-margin font-size18 xs-font-size16 text-medium-gray padding-twelve-top sm-padding-five-top xs-no-padding">Hello, My  name is </h5>
                            <h1 className="text-uppercase font-size40 md-font-size36 sm-font-size30 xs-font-size24 font-weight-700 margin-20px-bottom">Adekunle Adekoya</h1>
                            
                            <div className="row  border-top border-bottom">
                                <div className="col-md-6">
                                    <p className="width-100 font-size16 xs-font-size14">I'm a Software Engineer</p>
                                </div>
                                <div className="col-md-6">
                                    <span className="font-size30 xs-font-size24">
                                        <i class="fab fa-php"></i> 
                                        &nbsp;&nbsp;
                                        <i class="fab fa-js-square"></i>
                                    </span>
                                </div>  
                            </div>
                            
                            <div className="social-links margin-30px-bottom xs-margin-20px-bottom">
                                <a href="https://www.linkedin.com/in/crystoline/"><i className="fab fa-linkedin-in"></i></a>
                                <a href="https://twitter.com/xrystoline"><i className="fab fa-twitter"></i></a>
                                <a href="https://twitter.com/xrystoline" ><i className="fab fa-google-plus-g"></i></a>
                                <a href="https://www.facebook.com/crysto.org/"><i className="fab fa-facebook-f"></i></a>
                                <a href="https://github.com/crystoline"><i className="fab fa-github"></i></a>
                                {/* <a href="javascript:void(0);"><i className="fab fa-youtube"></i></a> */}
                            </div>
                            <a className="btn white" href="#portfolio">View My Portfolio <i className="fas fa-arrow-right font-size15 margin-5px-left"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
       )
    }
}

export default Home
