import React, { Component } from 'react'

export class About extends Component {
    constructor(props){
        super(props);
        this.state = {
            download: false,
            year: (new Date()).getFullYear() - (new Date('2012-05-01') ).getFullYear(),
            skillbars: [
                {
                    title: 'C#',
                    level: 85
                },
                {
                    title: 'PHP',
                    level: 88
                },
                {
                    title: 'JavaScript',
                    level: 90
                },
                {
                    title: 'Html',
                    level: 99
                },
                {
                    title: 'CSS',
                    level: 79
                }
            ]
        }
       // this.toggleDownload.bind(this);
    }
    toggleDownload = (e) =>{
        //console.log(e.target);
        if(e.target && e.target.title === 'download' ) return;

        this.setState(({download, year, skillbars}) => {
            return {download:!download, year, skillbars}
        });
    }

    componentDidMount(){
        var event_name = 'scroll'
        // Create the event
        var event = new CustomEvent(event_name, { "detail": "Example of an event" });

        // Dispatch/Trigger/Fire the event
        document.dispatchEvent(event);
    }
    render() {
        return (
            <section className="mt-2" data-scroll-index="1">
            <div className="container">
                <div className="row">
                    <div className="col-12 center-col margin-70px-bottom sm-margin-40px-bottom xs-margin-30px-bottom text-center">
                        <h2 className="font-size34 md-font-size30 sm-font-size26 xs-font-size24 font-weight-700 section-title">About Me</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-12 sm-margin-30px-bottom">
                        <p className="font-size15 xs-font-size14">
                            I have over { this.state.year} years experienced with demonstrated history of  Web, Mobile, and Desktop Application Development. 
                            Skilled in HTML5, CSS, JavaScript, C# and PHP.
                        </p>
                        <a className="btn white" href="javascript:void(0);" onClick={this.toggleDownload.bind(this)}>
                            <i className="fas fa-arrow-alt-circle-down font-size16"></i>
                            <span className="text-info mr-2 ml-2" style={{display: this.state.download? '':'none'}}>
                                <u  href="jdjjsdjsdsdjdjjkdsj" title="download">Click Here</u>
                            </span>
                            <span className="alt-font margin-5px-left">   
                                Download My CV
                            </span>
                        </a>

                        <div data-iframe-width="150" data-iframe-height="270" data-share-badge-id="390cac2c-78b3-471b-834f-7c4406de478c"></div>
                        <script type="text/javascript" async src="//cdn.youracclaim.com/assets/utilities/embed.js"></script>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="padding-20px-left sm-no-padding">
                            <div className="skills width-100 margin-40px-bottom sm-margin-30px-bottom xs-no-margin-bottom">
                                {
                                    this.state.skillbars && this.state.skillbars.map((skillbar, index) => (
                                        <div className="prog-item" key={index}>
                                            <p>{skillbar.title}</p>
                                            <div className="skills-progress"><span data-value={`${skillbar.level}%`}></span></div>
                                        </div>
                                    ))
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        )
    }
}

export default About
