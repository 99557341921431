import React, { Component } from 'react'
import {Link} from 'react-router-dom';

export class Menu extends Component {
    render() {
        return (
            <React.Fragment>
                <li class="nav-item">
                <Link className="nav-link"  to="/">Home</Link>
                </li>
                <li class="nav-item">
                    <Link className="nav-link"  to="/about">About</Link>
                </li>
            
                <li class="nav-item">
                    <Link class="nav-link"  to="/contact">Contact</Link>
                </li>
                <li class="nav-item">
                    <Link class="nav-link"  to="/portfolio">Portfolio</Link>
                </li>
            </React.Fragment>
        )
    }
}

export default Menu
