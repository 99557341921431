import React, { Component } from 'react'

export class Contact extends Component {
    render() {
        return (
            <section class="contact no-padding-bottom" data-scroll-index="6">
            <div class="container margin-100px-bottom md-margin-70px-bottom sm-margin-50px-bottom">
                <div class="row">
                    <div class="col-12 center-col margin-70px-bottom sm-margin-40px-bottom xs-margin-30px-bottom text-center">
                        <h3 class="font-weight-700 font-size32 md-font-size27 sm-font-size24 xs-font-size20 section-title">Contact</h3>
                    </div>
                </div>
                <div class="row">
    
                    <div class="col-lg-12">
                            <form method="post" action="bat/rd-mailform.php" class="mailform off2">
                                <input type="hidden" name="form-type" value="contact"/>
                                <div class="row">
                                    <div class="col-md-6">
                                        <input type="text" name="name" placeholder="Your Name:" data-constraints="@LettersOnly @NotEmpty"/>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" name="phone" placeholder="Telephone:" data-constraints="@Phone"/>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" name="email" placeholder="Email:" data-constraints="@Email @NotEmpty"/>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" name="subject" placeholder="Subject:" data-constraints="@NotEmpty"/>
                                    </div>                                
                                    <div class="col-md-12">
                                        <textarea name="message" rows="5" placeholder="Message:" data-constraints="@NotEmpty"></textarea>
                                    </div>
                                    <div class="mfControls col-md-12 text-center">
                                        <button type="submit" class="btn white">Send Message</button>
                                    </div>
                                </div>
                            </form>
                    </div>
    
                </div>
            </div>
    
            <div class="bg-light-gray padding-30px-tb border-top border-color-medium-gray">
                <div class="container">
                    <div class="row info">
    
    
                        <div class="col-lg-4 col-md-3 item text-center border-right xs-no-border-right xs-border-bottom border-color-medium-gray xs-margin-20px-bottom xs-padding-20px-bottom">
                            <span class="icon font-size32"><i class="icon-phone"></i></span>
                            <div class="cont">
                                <h6>Phone</h6>
                                <p>(+44) 123 456 789</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-5 item text-center border-right xs-no-border-right xs-border-bottom border-color-medium-gray xs-margin-20px-bottom xs-padding-20px-bottom">
                            <span class="icon font-size32"><i class="icon-map"></i></span>
                            <div class="cont">
                                <h6>Address</h6>
                                <p>3389 Eglinton Avenue, London</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 item text-center">
                            <span class="icon font-size32"><i class="icon-envelope"></i></span>
                            <div class="cont">
                                <h6>Email</h6>
                                <p>email@youradress.com</p>
                            </div>
                        </div>
    
                    </div>
                </div>
            </div>
    
        </section>
    
        )
    }
}

export default Contact
