import React, { Component } from 'react'

export class Portfolio extends Component {
    render() {
        return (
            <section className="portfolio" id="portfolio">
                <div className="container">
                    <div className="row">
                        <div className="col-12 center-col margin-70px-bottom sm-margin-40px-bottom xs-margin-30px-bottom text-center">
                            <h3 className="font-weight-700 font-size32 md-font-size27 sm-font-size24 xs-font-size20 section-title">My Portfolio</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="filtering col-sm-12 text-center">
                            <span data-filter='*' className="active">All</span>
                            <span data-filter='.develop'>Develop</span>
                            <span data-filter='.design'>Design</span>
                            <span data-filter='.graphic'>Graphic</span>
                        </div>
                        <div className="clearfix"></div>
        
                        <div className="gallery text-center width-100">
        
                            <div className="col-md-4 items graphic develop">
                                <div className="item-img">
                                    <img src="http://www.chitrakootweb.com/template/ashton/img/portfolio/1.jpg" alt="image"/>
                                    <div className="item-img-overlay valign">
                                        <div className="overlay-info width-100 vertical-center">
                                            <a href="http://www.chitrakootweb.com/template/ashton/img/portfolio/1.jpg" className="popimg">
                                                <i className="fa fa-search-plus"></i>
                                            </a>
                                            <h6>Show your time</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-md-4 items design">
                                <div className="item-img">
                                    <img src="http://www.chitrakootweb.com/template/ashton/img/portfolio/2.jpg" alt="image"/>
                                    <div className="item-img-overlay valign">
                                        <div className="overlay-info width-100 vertical-center">
                                            <a href="http://www.chitrakootweb.com/template/ashton/img/portfolio/2.jpg" className="popimg">
                                                <i className="fa fa-search-plus"></i>
                                            </a>
                                            <h6>Creative design</h6>
                                        </div>
        
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-md-4 items develop graphic">
                                <div className="item-img">
                                    <img src="http://www.chitrakootweb.com/template/ashton/img/portfolio/3.jpg" alt="image" />
                                    <div className="item-img-overlay valign">
                                        <div className="overlay-info width-100 vertical-center">
                                            <a href="http://www.chitrakootweb.com/template/ashton/img/portfolio/3.jpg" className="popimg">
                                                <i className="fa fa-search-plus"></i>
                                            </a>
                                            <h6>Tag Design Mockup</h6>
                                        </div>
        
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-md-4 items design">
                                <div className="item-img">
                                    <img src="http://www.chitrakootweb.com/template/ashton/img/portfolio/4.jpg" alt="image" />
                                    <div className="item-img-overlay valign">
                                        <div className="overlay-info width-100 vertical-center">
                                            <a href="http://www.chitrakootweb.com/template/ashton/img/portfolio/4.jpg" className="popimg">
                                                <i className="fa fa-search-plus"></i>
                                            </a>
                                            <h6>Fries Mockup</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-md-4 items design">
                                <div className="item-img">
                                    <img src="http://www.chitrakootweb.com/template/ashton/img/portfolio/5.jpg" alt="image" />
                                    <div className="item-img-overlay valign">
                                        <div className="overlay-info width-100 vertical-center">
                                            <a href="http://www.chitrakootweb.com/template/ashton/img/portfolio/5.jpg" className="popimg">
                                                <i className="fa fa-search-plus"></i>
                                            </a>
                                            <h6>Creative Design</h6>
                                        </div>
        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 items develop graphic">
                                <div className="item-img">
                                    <img src="http://www.chitrakootweb.com/template/ashton/img/portfolio/6.jpg" alt="image" />
                                    <div className="item-img-overlay valign">
                                        <div className="overlay-info width-100 vertical-center">
                                            <a href="http://www.chitrakootweb.com/template/ashton/img/portfolio/6.jpg" className="popimg">
                                                <i className="fa fa-search-plus"></i>
                                            </a>
                                            <h6>Apple product design</h6>
                                        </div>
        
                                    </div>
                                </div>
                            </div>
        
                            <div className="clear-fix"></div>
        
                        </div>
                    </div>
                </div>
          
            </section>
        )
    }
}

export default Portfolio
