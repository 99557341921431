import React, { Component} from 'react';
import { Route, Link } from 'react-router-dom';
import Home from './sections/Home';
import Menu from './sections/Menu';

export default class Header extends Component {


    render(){
        return (
        <header className="header" data-scroll-index="0">
            <nav className="navbar navbar-expand-lg border-none bg-light-gray">
                <div className="container sm-padding-10px-tb sm-padding-15px-lr">

                    <Link className="logo"  data-scroll-nav="0">
                        <span className="font-bold font-size28 text-black" style={{fontWeight: 800}}>CRYSTO</span>
                    </Link>

                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="icon-bar"><i className="fas fa-bars"></i></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarContent">
                        <ul className="navbar-nav ml-auto">
                            <Menu />
                        </ul>
                    </div>

                </div>

                <a href="javascript:void(0)" className="sidemenu_btn sm-display-none" id="sidebar_toggle">
                    <span></span> <span></span> <span></span>
                </a>

            </nav>
            
            <Route path="/" exact component={Home} />
           
            
            <div className="side-menu">
                <div className="inner-wrapper">
                    <span className="btn-close" id="btn_sidebar_colse"></span>
                    <nav className="side-nav width-100">
                        <ul className="navbar-nav ml-auto">
                            <Menu />
                        </ul>
                    </nav>

                    <div className="side-footer width-100">
                        <div className="social-links">
                            <a href="javascript:void(0);"><i className="fab fa-facebook-f"></i></a>
                            <a href="javascript:void(0);"><i className="fab fa-twitter"></i></a>
                            <a href="javascript:void(0);"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <a id="close_sidebar" href="javascript:void(0);"></a>
        </header>
        );
    }
}
